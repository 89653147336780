//@import "reset";
@font-face {
  font-family: "Flexible";
  src: local("Flexible"), url("../fonts/Flexible-H400W200.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-5FF;

  //font-display: optional;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

* {
  outline: none !important;
  box-shadow: none;
}

html {
  -webkit-font-smoothing: antialiased;
  //background-color: #204aa1;
  color: #fff;
  height: 100%;
  //min-height: 100vh;
  //height: -webkit-fill-available;
  line-height: 1.15;
  // overflow-x: hidden;
  background-color: rgb(112, 157, 201);
  background: linear-gradient(-90deg, rgba(112, 157, 201, 1) 0%, rgba(162, 208, 226, 1) 29%, rgba(32, 74, 161, 1) 100%);
  // background-size: cover;

  @media screen and (max-width: 912px) {
    background: linear-gradient(-90deg, rgba(112, 157, 201, 1) 0%, rgba(32, 74, 161, 1) 100%);
  }
}

::-ms-input-placeholder {
  color: #69c5f6;
}

::-webkit-input-placeholder {
  color: #69c5f6;
}

::-moz-placeholder {
  color: #69c5f6;
}

:-ms-input-placeholder {
  color: #69c5f6;
}

::placeholder {
  color: #69c5f6;
}

::selection,
::selection {
  background-color: #214ba2;
  color: #fff;
}

input,
button,
textarea,
select {
  font: inherit;
}

input {
  border: 1px solid #333;
  transition: all 0.5s ease;
}

input:invalid:not(:focus):not(:placeholder-shown),
invalid:focus:not(:placeholder-shown) {
  outline: none !important;
  transition: all 0.5s ease;
  border: 1px solid red;
}

input:not(:placeholder-shown):valid {
  outline: none !important;
  transition: all 0.5s ease;
  border: 1px solid green;
}

input:focus {
  outline: none !important;
  transition: all 0.5s ease;
  border: 1px solid #000;
  transition: all 0.5s ease;
}

// INDEX PAGE

body {
  //background: url("../images/pageapp-bg.jpg") repeat center center fixed;
  // background-image: url("../images/pageapp-bg.jpg");
  // background: radial-gradient(90.83% 94.23% at 90.83% 58.89%, #a8d7e5 0%, #204aa1 100%);
  // mix-blend-mode: multiply;

  // background-color: red;

  bottom: 0;
  color: #fff;
  display: flex;
  font-family: Poppins, sans-serif;
  height: 100%;
  left: 0;
  //min-height: 100vh;
  //min-height: -webkit-fill-available;
  //overflow-x: hidden;
  position: relative;
  right: 0;
  top: 0;
  z-index: 1;

  @media screen and (max-width: 560px) {
    // height: auto;
    min-height: unset;
  }

  @media screen and (max-width: 912px) {
    background-position: center right;
  }

  // @media screen and (max-width: 991px) {
  //   background: radial-gradient(90.83% 94.23% at 90.83% 58.89%, #a8d7e5 0%, #204aa1 100%);
  //   // mix-blend-mode: multiply;
  // }

  #root,
  .App {
    overflow: hidden;
    isolation: isolate;
    width: 100%;
    font-family: Poppins, sans-serif;

    @media screen and (min-width: 1600px) {
      min-height: 100vh;
      min-height: -webkit-fill-available;
    }
  }

  .my-custom-control {
    display: flex;
    background-color: #ff0000;
    width: 100%;
    height: 20px;
    color: black;
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
  }

  .App {
    // display: flex;
    // justify-content: center;

    @media screen and (max-width: 912px) {
      display: block;
    }
  }

  .container {
    width: 100%;
    padding-left: 64px;
    @media screen and (max-width: 1280px) {
      width: 94%;
    }
  }

  .home-fork {
    height: 100vh;

    @keyframes float {
      0% {
        transform: translatey(0px);
      }
      50% {
        transform: translatey(-8px);
      }
      100% {
        transform: translatey(0px);
      }
    }

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    .full-width-container {
      width: 100%;
      padding: 0 20px 0 20px;
    }
    .container {
      height: 100%;
      width: 100%;
      position: relative;
      @media screen and (max-width: 991px) {
        padding: 0 20px;
      }
    }

    &-header {
      margin-bottom: 40px;
      width: 100%;
      background-color: transparent;
      position: fixed;
      z-index: 10;
      &.fixed {
        background-color: rgba(63, 63, 64, 0.85);
        // background: rgba(168, 215, 229, 0.37);
        // box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        // border: 0.5px solid rgba(168, 215, 229, 0.04);
        backdrop-filter: blur(8.2px);
        -webkit-backdrop-filter: blur(8.2px);
      }
    }
    &-body {
      margin-bottom: 20px;
      height: 100%;
      @media screen and (max-height: 1024px) {
        // padding-top: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100vh - 140px);
      }
    }
    &-content {
      position: relative;
      display: flex;
      flex-direction: row;
      padding-top: 140px;
      height: 100%;
      @media screen and (max-width: 991px) {
        flex-direction: column;
        padding-top: 120px;
      }
      @media screen and (max-width: 550px) {
        flex-direction: column;
        padding-top: 180px;
      }
      .home-fork-left-col {
        padding-left: 10px;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media screen and (max-width: 991px) {
          width: 100%;
          padding-left: 0px;
        }
        .home-fork-title {
          font-family: Poppins, sans-serif;
          font-weight: 900;
          font-size: clamp(88px, 6vw, 200px);
          line-height: 110%;
          letter-spacing: 0px;
          text-transform: uppercase;
          color: #ffffff;
          margin-bottom: 20px;

          span {
            display: block;

            opacity: 0.45;
            margin-bottom: 20px;
            @media screen and (max-width: 991px) {
              margin-bottom: 0px;
            }
          }
          @media screen and (max-width: 991px) {
            font-size: clamp(46px, 5vw, 200px);
            line-height: 110%;

            // display: none;
          }
        }

        .home-fork-mobile-title-mobile1,
        .home-fork-mobile-title-mobile2,
        .home-fork-mobile-title-mobile3 {
          font-family: "Flexible", sans-serif;
          font-weight: 500;
          font-size: 200px;
          text-transform: uppercase;
          color: #fff;
          position: absolute;
          opacity: 0;
          animation: fadeIn 2.4s 1.2s ease-in-out forwards;
          @media screen and (max-width: 625px) {
            font-size: 160px;
          }
        }
        .home-fork-mobile-title-mobile1 {
          top: 5%;
          @media screen and (max-width: 625px) {
            top: 10%;
          }
        }
        .home-fork-mobile-title-mobile2 {
          top: 40%;
          @media screen and (max-width: 625px) {
            top: 43%;
          }
        }
        .home-fork-mobile-title-mobile3 {
          bottom: 0px;
          @media screen and (max-width: 625px) {
            bottom: -5%;
          }
        }
        .home-fork-mobile-globe {
          display: none;

          justify-content: center;
          align-items: center;
          position: relative;
          top: -40px;

          img {
            max-width: 80%;
            transform: translateY(0);
            animation: float 8s ease-in-out infinite;
            @media screen and (max-width: 625px) {
              max-width: 110%;
            }
          }
          @media screen and (max-width: 991px) {
            padding-top: 200px;
            display: flex;
            flex-direction: column;
            position: relative;
            justify-content: center;
            align-items: center;
          }
        }
        .home-fork-text {
          font-family: Poppins, sans-serif;
          max-width: 500px;
          border-left: 1px solid #fff;
          padding: 10px 0 10px 20px;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 2.5px;
          margin-bottom: 20px;

          @media screen and (max-width: 991px) {
            margin-bottom: 40px;
            padding: 0;
            max-width: unset;
            border: none;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 2.5px;
            text-align: center;
          }
        }
        .home-fork-btn {
          font-weight: 800;
          font-size: 28px;
          line-height: 24px;
          font-family: Poppins, sans-serif;

          text-align: center;
          letter-spacing: 4px;
          text-transform: uppercase;

          color: #214ba2;
          text-decoration: none;
          text-align: center;
          background: linear-gradient(
            68.54deg,
            rgba(255, 255, 255, 0.8) 59.06%,
            rgba(255, 255, 255, 0.64) 86.32%,
            rgba(255, 255, 255, 0.48) 100%
          );
          box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
          backdrop-filter: blur(4px);
          border-radius: 8px;
          width: 100%;
          max-width: 410px;
          display: block;
          padding: 20px;
          transition: all 0.3s;
          margin-bottom: 30px;

          @media screen and (max-width: 991px) {
            max-width: unset;
          }

          &:hover {
            background-color: #fff;
            box-shadow: none;
          }
        }
        .home-fork-stats {
          @media screen and (max-width: 991px) {
            display: none;
          }
        }
      }
      .home-fork-wrapper {
        height: 20vh;
        min-height: calc(7vh + 74px);
      }
      .home-fork-right-col {
        padding-bottom: 40px;
        position: relative;
        // width: 100%;
        // max-width: 800px;
        .home-fork-globe {
          position: absolute;
          left: -10vw;
          width: 70vw;
          padding-bottom: 40px;
          height: auto;
          transform: translateY(0);
          animation: float 8s ease-in-out infinite;
          @media screen and (max-width: 1075px) {
            max-width: 800px;
            right: -40%;
          }
        }

        @media screen and (max-width: 991px) {
          .home-fork-globe {
            // display: none;
            width: 100vw;
            left: 10vw;
          }
        }
      }
    }
  }

  .floating-logo {
    position: absolute;
    top: 40px;
    left: 20px;
    z-index: 10;
  }

  .socials-floating {
    position: absolute;
    bottom: 20px;
    left: 20px;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(90deg, #0c086c 0%, #200cbf 100%);
    opacity: 0.9;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    border-radius: 8px;
    padding: 15px;

    .socials-section {
      display: flex;
      justify-content: center;
      align-items: center;

      .social-item {
        margin: 0 7px;

        svg {
          fill: #fff;
          transition: all 0.3s;

          &:hover {
            fill: #69c5f6;
          }
        }
      }
    }
  }

  .header-section {
    display: flex;
    justify-content: space-between;
    padding: 18px 0;

    @media screen and (max-width: 991px) {
      padding: 10px 0;
      .header-section-logo {
        display: flex;
        width: 32px;
        img {
          width: 100%;
        }
      }
    }

    .socials {
      a {
        margin: 0 6px;
        color: #fff;
      }
    }

    @media screen and (max-width: 1024px) {
      //justify-content: center;
    }

    @media screen and (max-width: 920px) {
      // justify-content: center;
    }

    .mobile-menu {
      font-family: Poppins, sans-serif;
      position: fixed;
      height: 100vh;
      right: -270px;
      width: 270px;
      top: 0;
      bottom: 0;
      z-index: 1000;
      background: linear-gradient(182.2deg, rgba(255, 255, 255, 0.9) -17.3%, #ffffff 100%);
      box-shadow: 0px 4px 20px 4px rgba(0, 0, 0, 0.25);
      backdrop-filter: blur(8.2px);
      border-radius: 18px 0px 0px 18px;
      color: #214ba2;
      padding: 25px;
      display: block;
      transition: all 0.3s;
      &.active {
        right: 0px;
      }
      &-close {
        margin-bottom: 70px;
        padding: 5px 5px 5px 0px;
      }
      &-stats {
        border: 1px solid #214ba2;
        border-radius: 12px;
        padding: 24px;
        margin-bottom: 24px;
        &-line {
          margin-bottom: 22px;
          &:last-child {
            margin-bottom: 0;
          }
          .mobile-menu-stats-name {
            font-weight: 600;
            letter-spacing: 1px;
            text-transform: uppercase;
            font-size: 12px;
            margin-bottom: 10px;
          }
          .mobile-menu-stats-value {
            font-weight: 600;
            font-size: 24px;
            line-height: 20px;
            letter-spacing: 1px;
            text-transform: uppercase;
          }
        }
      }
      &-buy-btn {
        background: #214ba2;
        border-radius: 8px;
        text-align: center;
        display: block;
        padding: 11px;
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 2.5px;
        text-transform: uppercase;
        text-decoration: none;
        color: #ffffff;
        margin-bottom: 40px;
        &:hover {
          background: #3e5fa1;
        }
      }
      &-socials {
        .socials-section {
          display: flex;
          justify-content: space-between;
          svg {
            fill: #214ba2;
          }
        }
      }
    }

    .mobile-menu-btn {
      display: none;
      padding: 10px;

      @media screen and (max-width: 991px) {
        display: block;
      }
    }

    .links {
      display: flex;

      // @media screen and (max-width: 991px) {
      //   display: none;
      // }

      svg {
        width: 25px;
        height: 25px;
        fill: #fff;
        transition: all 0.3s;

        &:hover {
          fill: rgba(12, 8, 108, 0.7);
        }
      }

      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .link {
        margin: 0 16px;
      }
    }

    .link {
      font-weight: 600;
      font-size: 20px;
      line-height: 18px;
      display: flex;
      align-items: center;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: #ffffff;
      text-decoration: none;
      transition: all 0.3s;

      &:hover {
        // color: #200cbf;
        color: rgba(12, 8, 108, 0.7);
      }
    }
  }

  .container .container-inner {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 80px;
    min-height: 800px;

    @media screen and (max-width: 912px) {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      margin-bottom: 10px;
    }

    @media screen and (min-width: 1600px) {
      margin-top: 40px;
      margin-bottom: 0px;
      justify-content: center;
      height: 100vh;
    }
  }

  // TEXT COLUMN
  .text-colum {
    // align-items: center;
    display: flex;
    flex-basis: 50%;
    flex-direction: column;
    justify-content: center;
    width: 50%;

    @media screen and (max-width: 912px) {
      flex-basis: 100%;
      width: 100%;
    }

    @media screen and (min-width: 1920px) {
      align-items: self-end;
      padding-right: 5%;
    }

    @media screen and (min-width: 2650px) {
      flex-basis: 40%;
      width: 40%;
    }

    .text-colum-inner {
      display: flex;
      flex-direction: column;
      // margin-left: 100px;
      width: 500px;

      // @media screen and (max-width: 1024px) {
      //   margin-left: 50px;
      // }

      @media screen and (max-width: 912px) {
        margin-left: 0;
        padding: 0 20px;
        width: 100%;
        align-items: center;
      }
    }

    .subscribe-inner-align {
      // max-width: 430px;
      // width: 100%;
      // @media screen and (min-width: 560px) {
      //   padding: 0 40px;
      // }
      padding: 0 40px;

      @media screen and (min-width: 769px) {
        // max-width: 430px;
      }
    }

    .globe-mobile-out {
      min-height: 370px;
    }

    .globe-mobile {
      display: none;

      &-img {
        width: 100%;
      }

      @media screen and (max-width: 912px) {
        display: block;
        padding: 0 20px;
        margin-bottom: 50px;
      }

      @media screen and (max-width: 320px) {
        margin-bottom: 30px;
      }
    }

    .d3-globe {
      opacity: 0;

      &.globe-visible {
        opacity: 1;
        transition: all 0.7s ease;
        -webkit-transition: all 1s;
      }
    }

    .top-logo {
      margin-bottom: 10px;
      margin-left: -18px;

      @media screen and (max-width: 912px) {
        margin-left: 0px;
        margin-top: 40px;
      }

      @media screen and (max-width: 912px) {
        width: 100%;
        max-width: 320px;
        margin-top: 40px;
      }
    }

    .sub-header {
      font-family: Poppins, sans-serif;
      font-weight: 400;
      font-size: 40px;
      line-height: 56px;
      display: flex;
      align-items: center;
      letter-spacing: 8px;
      text-transform: uppercase;
      color: #ffffff;

      @media screen and (max-width: 1024px) {
        text-align: center;
      }

      @media screen and (max-width: 768px) {
        font-size: 36px;
        letter-spacing: 5px;
      }
    }

    .text-out {
      display: flex;
      justify-content: center;
      background-image: url("../images/text-bg.svg");
      background-repeat: no-repeat;
      padding: 70px 0 50px 70px;
      background-size: contain;
      background-position-x: 12px;
      margin-bottom: 10px;

      @media screen and (max-width: 912px) {
        background: none;
        padding: 18px 0 30px 0;
      }
    }

    .text {
      width: 100%;
      max-width: 390px;
      line-height: 24px;
      letter-spacing: 4px;

      @media screen and (max-width: 912px) {
        max-width: unset;
        text-align: center;
        letter-spacing: unset;
      }

      @media screen and (max-width: 320px) {
        font-size: 14px;
      }
    }

    .coming-soon-text {
      font-size: 42px;
      line-height: 56px;
      letter-spacing: 10px;
      text-transform: uppercase;
      margin-bottom: 18px;
      font-weight: 400;

      @media screen and (max-width: 700px) {
        font-size: 32px;
      }
    }

    .subtitle-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 2px;
      margin-bottom: 18px;
      padding-right: 40px;

      @media screen and (max-width: 912px) {
        padding-right: 0px;
        text-align: center;
      }

      @media screen and (max-width: 320px) {
        font-size: 14px;
      }
    }

    .subscribe-section {
      margin-bottom: 30px;
      position: relative;
      z-index: 1;

      @media screen and (max-width: 912px) {
        width: 100%;
        max-width: 640px;
      }

      @media screen and (max-width: 560px) {
        margin-bottom: 20px;
      }
    }

    .subscribe-inner {
      display: flex;
    }

    .subscribe-input-text {
      max-height: 56px;
      width: 100%;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 2px;
      color: #200cbf;
      padding: 16px;
      border: none;
      background: linear-gradient(
        68.54deg,
        rgba(255, 255, 255, 0.6) 59.06%,
        rgba(255, 255, 255, 0.48) 86.32%,
        rgba(255, 255, 255, 0.36) 100%
      );
      box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
      -webkit-backdrop-filter: blur(3px);
      backdrop-filter: blur(3px);
      border-radius: 8px 0px 0px 8px;
      transition: all 0.75s ease;

      ::-webkit-input-placeholder {
        color: #200cbf;
        -webkit-transition: color 0.3s;
        transition: color 0.3s;
      }

      ::-moz-placeholder {
        color: #200cbf;
        -moz-transition: color 0.3s;
        transition: color 0.3s;
      }

      :-ms-input-placeholder {
        color: #200cbf;
        -ms-transition: color 0.3s;
        transition: color 0.3s;
      }

      ::-ms-input-placeholder {
        color: #200cbf;
        -ms-transition: color 0.3s;
        transition: color 0.3s;
      }

      ::placeholder {
        color: #200cbf;
        transition: color 0.3s;
      }

      &:focus {
        outline: none !important;

        &::-webkit-input-placeholder {
          -webkit-transition: color 0.3s;
          transition: color 0.3s;
          color: rgba(32, 12, 191, 0.3);
        }

        &::-moz-placeholder {
          -moz-transition: color 0.3s;
          transition: color 0.3s;
          color: rgba(32, 12, 191, 0.3);
        }

        &:-ms-input-placeholder {
          -ms-transition: color 0.3s;
          transition: color 0.3s;
          color: rgba(32, 12, 191, 0.3);
        }

        &::-ms-input-placeholder {
          -ms-transition: color 0.3s;
          transition: color 0.3s;
          color: rgba(32, 12, 191, 0.3);
        }

        &::placeholder {
          transition: color 0.3s;
          color: rgba(32, 12, 191, 0.3);
        }
      }

      @media screen and (max-width: 912px) {
        // width: 280px;
        font-size: 14px;
        padding: 9px 12px;
      }

      @media screen and (max-width: 320px) {
        font-size: 12px;
        // width: 220px;
      }
    }

    .mail-error-text {
      color: red;
      font-size: 12px;
      letter-spacing: 1px;
      font-weight: 600;
      margin-top: 5px;
      text-transform: uppercase;
      height: 14px;
    }

    .subscribe-done {
      background: linear-gradient(90deg, rgba(12, 8, 108, 0.2) 0%, rgba(32, 12, 191, 0.2) 100%);
      padding: 16px;
      border: 1px solid #ffffff;
      box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
      -webkit-backdrop-filter: blur(3px);
      backdrop-filter: blur(3px);
      border-radius: 8px;
      justify-content: space-between;
      align-items: center;
      display: flex;

      &-text {
        font-weight: 600;
        font-size: 18px;
        letter-spacing: 2.5px;

        @media screen and (max-width: 560px) {
          font-size: 14px;
          letter-spacing: 2px;
        }
      }

      &-resend {
        cursor: pointer;

        &:hover {
          -webkit-animation: spin-resend 1.7s linear 2;
          animation: spin-resend 1.7s linear 2;
        }
      }
    }

    @-webkit-keyframes spin-resend {
      0% {
        transform: rotate(0);
      }

      100% {
        transform: rotate(-360deg);
      }
    }

    @keyframes spin-resend {
      0% {
        transform: rotate(0);
      }

      100% {
        transform: rotate(-360deg);
      }
    }

    ::-webkit-input-placeholder {
      color: #200cbf;
      letter-spacing: 4px;

      @media screen and (max-width: 912px) {
        letter-spacing: 2px;
      }
    }

    :-ms-input-placeholder {
      color: #200cbf;
      letter-spacing: 4px;

      @media screen and (max-width: 912px) {
        letter-spacing: 2px;
      }
    }

    ::-moz-placeholder {
      color: #200cbf;
      letter-spacing: 4px;

      @media screen and (max-width: 912px) {
        letter-spacing: 2px;
      }
    }

    ::-ms-input-placeholder {
      color: #200cbf;
      letter-spacing: 4px;

      @media screen and (max-width: 912px) {
        letter-spacing: 2px;
      }
    }

    ::placeholder {
      color: #200cbf;
      letter-spacing: 4px;

      @media screen and (max-width: 912px) {
        letter-spacing: 2px;
      }
    }

    .subscribe-bg {
      position: relative;
      width: 100%;
    }

    .subscribe-input-submit-wrapper {
      position: relative;
    }

    .form-input-status-out {
      height: 16px;
      overflow: hidden;
      margin-top: 7px;
      margin-bottom: 10px;

      .form-input-status {
        transition: all 0.7s;
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
    }

    .subscribe-input-submit {
      background-color: rgba(255, 255, 255, 0.46);
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAxNyAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuMTYyODQyIDAuNjM5OTk5SDcuNTk2ODRMMTYuNzUyOCAxMC4wNDhMNy41OTY4NCAxOS40NTZIMC4xNjI4NDJMOS4zNjA4NCAxMC4wNDhMMC4xNjI4NDIgMC42Mzk5OTlaIiBmaWxsPSIjMjAwQ0JGIi8+Cjwvc3ZnPgo=");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 20px 20px;
      box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
      -webkit-backdrop-filter: blur(3px);
      backdrop-filter: blur(3px);
      border-radius: 0px 8px 8px 0px;
      width: 55px;
      height: 56px;
      border: none;
      margin-left: 2px;
      cursor: pointer;
      transition: all 0.75s ease;

      &:hover {
        background-color: rgba(12, 8, 108, 0.7);
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAxNyAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuMTYyODQyIDAuNjM5OTk5SDcuNTk2ODRMMTYuNzUyOCAxMC4wNDhMNy41OTY4NCAxOS40NTZIMC4xNjI4NDJMOS4zNjA4NCAxMC4wNDhMMC4xNjI4NDIgMC42Mzk5OTlaIiBmaWxsPSIjZmZmZmZmIi8+Cjwvc3ZnPgo=");
        box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
      }

      @media screen and (max-width: 912px) {
        width: 42px;
        height: 42px;
      }
    }

    .socials-section {
      display: flex;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: 912px) {
        margin-bottom: 25px;
      }
    }

    .social-item {
      margin-right: 12px;
      transition: all 0.7s;

      svg {
        fill: #fff;
        transition: all 0.3s;

        &:hover {
          fill: rgba(12, 8, 108, 0.7);
        }
      }

      a {
        font-size: 14px;
        line-height: 24px;
        color: #fff;
        letter-spacing: 2px;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }

  // Index Token Stats section
  .token-stats {
    border: 1px solid #fff;
    border-radius: 12px;
    padding: 24px;
    width: 100%;
    max-width: 410px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;

    &-line {
      display: flex;
      justify-content: center;

      &:first-child {
        margin-bottom: 24px;
      }

      @media screen and (max-width: 320px) {
        flex-direction: column;
      }
    }

    &-change {
      //font-family: "Poppins";
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 1px;
      text-transform: uppercase;
      display: inline-block;
      margin-left: 4px;

      &.positive-change {
        color: #00ff00;

        @media screen and (max-width: 912px) {
          font-size: 10px;
        }
      }

      &.negative-change {
        color: #ff0000;

        @media screen and (max-width: 912px) {
          font-size: 10px;
        }
      }
    }

    &-section {
      width: 100%;
    }

    &-buy {
      //font-family: "Poppins";
      font-weight: 700;
      font-size: 20px;
      padding: 13px;
      text-align: center;
      letter-spacing: 2.5px;
      text-transform: uppercase;
      color: #214ba2;
      background: linear-gradient(
        68.54deg,
        rgba(255, 255, 255, 0.8) 59.06%,
        rgba(255, 255, 255, 0.64) 86.32%,
        rgba(255, 255, 255, 0.48) 100%
      );
      box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(4px);
      border-radius: 8px;

      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      min-width: 165px;
      margin-right: 20px;
      transition: all 0.3s;

      @media screen and (max-width: 912px) {
        width: 100%;
      }

      @media screen and (max-width: 320px) {
        margin-bottom: 10px;
      }

      &:hover {
        background-color: #fff;
        box-shadow: none;
      }
    }

    &-title {
      //font-family: "Poppins";
      font-weight: 600;
      font-size: 12px;
      letter-spacing: 1px;
      text-transform: uppercase;
      text-align: center;
      line-height: 16px;

      @media screen and (max-width: 912px) {
        font-size: 10px;
      }

      &.top-title {
        text-align: left;
        margin-bottom: 10px;
      }
    }

    &-value {
      //font-family: "Poppins";
      font-weight: 600;
      font-size: 20px;
      letter-spacing: 1px;
      text-align: center;
      line-height: 24px;
      display: flex;
      justify-content: center;

      @media screen and (max-width: 912px) {
        font-size: 18px;
      }

      &.top-value {
        text-align: left;
        font-size: 32px;
        text-transform: uppercase;
        justify-content: flex-start;

        @media screen and (max-width: 912px) {
          font-size: 28px;
        }
      }
    }
  }

  // GLOBE COLUMN
  .globe-search {
    z-index: 10;
    position: absolute;
    top: 42px;
    right: 48px;
  }

  path.focused {
    fill: #e15cf9 !important;
  }

  .globe-colum {
    flex-basis: 50%;
    width: 50%;

    @media screen and (max-width: 912px) {
      flex-basis: 35%;
      width: 35%;
    }

    @media screen and (max-width: 912px) {
      // display: none;
      svg {
        width: 200%;
      }
    }

    svg {
      position: relative;
      right: -0%;
    }

    .bluebg {
      //background-color: #200cbf;
      fill: #200cbf;
    }

    .point {
      fill: #200cbf;
      //border: 1px solid #fff;
    }
  }

  .globe-wrapper {
    position: relative;
    transition: all 1.7s;
    height: auto;
    width: auto;
    top: 0;
    left: 0;
    background: radial-gradient(circle, rgba(12, 8, 108, 0.35) 2%, rgba(252, 70, 107, 0) 60%);
    transition: all 0.01s;

    &-full {
      //transition: all 1.7s;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      min-height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 100;
      background-color: rgba(12, 8, 108, 0.8);
      transition: all 0.15s;
      background: linear-gradient(90deg, rgba(12, 8, 108, 0.8) 0%, rgba(32, 12, 191, 0.8) 100%);
      -webkit-backdrop-filter: blur(0.5px);
      backdrop-filter: blur(0.5px);
      overflow: hidden;
    }

    @media screen and (max-width: 920px) {
      display: flex;
      justify-content: center;
    }

    .globe-close-btn {
      z-index: 11;
      position: absolute;
      top: 10px;
      right: 10px;
      width: 32px;
      height: 32px;
      border-radius: 16px;
      background-color: #69c5f6;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .globe-close {
        width: 24px;
        height: 24px;
        fill: #0c086c;
        transition: all 0.25s;
      }

      &:hover {
        background-color: #0c086c;

        .globe-close {
          fill: rgba(105, 197, 246, 1);
        }
      }
    }
  }

  .graticule {
    opacity: 0.3;
    z-index: -1;
    position: absolute;
  }

  .tooltip {
    text-align: left;
    color: #0c086c;
    padding: 22px;
    width: auto;
    opacity: 0;
    position: absolute;
    z-index: -100;
    background: linear-gradient(
      68.54deg,
      rgba(255, 255, 255, 0.8) 59.06%,
      rgba(255, 255, 255, 0.64) 86.32%,
      rgba(255, 255, 255, 0.48) 100%
    );
    border: 1px solid #fff;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    border-radius: 8px;

    &-close {
      position: relative;
      top: -10px;
      height: 10px;

      svg {
        position: absolute;
        right: -10px;
      }
    }

    &-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 3px;
      text-transform: uppercase;
      margin-bottom: 6px;

      @media screen and (max-width: 912px) {
        font-size: 16px;
      }
    }

    &-lat_lon {
      letter-spacing: 2px;
      margin-bottom: 20px;

      @media screen and (max-width: 912px) {
        font-size: 14px;
      }
    }

    &-related {
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 2px;
      margin-bottom: 2px;

      @media screen and (max-width: 912px) {
        font-size: 14px;
      }
    }

    &-related_list {
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 2px;
      -webkit-text-decoration-line: underline;
      text-decoration-line: underline;
      margin-bottom: 18px;

      @media screen and (max-width: 912px) {
        font-size: 10px;
      }
    }

    &-btn_explorer {
      display: block;
      background: linear-gradient(90deg, #0c086c 0%, #200cbf 100%);
      border: 1px solid #200cbf;
      border-radius: 8px;
      padding: 8px;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 3px;
      text-transform: uppercase;
      color: #69c5f6;
      text-decoration: none;
      text-align: center;
      transition: all 0.35s;

      @media screen and (max-width: 912px) {
        font-size: 16px;
      }

      &:hover {
        background: #fff;
        color: #0c086c;
      }
    }
  }

  // MAP PAGE

  .map-layout-lock {
    z-index: -1;
    &.active {
      position: absolute;
      background: linear-gradient(45deg, #0c086c 0%, #200cbf 100%);
      opacity: 0.9;
      backdrop-filter: blur(1px);
      -webkit-backdrop-filter: blur(1px);
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100vh;
      z-index: 10001;
    }
  }
  .map-layout-lock {
    &-password-box {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      z-index: 10002;
    }
    &-title,
    &-subtitle {
      font-family: "Poppins";
      text-transform: uppercase;
      text-align: center;
      color: #69c5f6;
    }
    &-title {
      font-weight: 600;
      font-size: 48px;
      letter-spacing: 4px;
      margin-bottom: 10px;
    }
    &-subtitle {
      font-weight: 300;
      font-size: 32px;
      letter-spacing: 3px;
      margin-bottom: 40px;
    }

    &-head {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 3px;
      text-transform: uppercase;
      color: #69c5f6;
      margin-bottom: 20px;
    }
    &-close {
      position: absolute;
      top: -20px;
      right: -20px;
      svg {
        transition: all 0.1s;
        width: 20px;
        height: 20px;
        fill: #69c5f6;
      }
      &:hover {
        svg {
          fill: #fff;
        }
      }
    }
    &-input-section {
      padding: 30px 0px;
      border: 1px solid #69c5f6;
      border-radius: 8px;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;
    }
    &-input {
      background-color: transparent;
      color: #69c5f6;
      border: 1px solid #69c5f6 !important;
      filter: drop-shadow(1px 2px 10px rgba(0, 0, 0, 0.1));
      backdrop-filter: blur(2px);
      -webkit-backdrop-filter: blur(2px);
      border-radius: 6px;
      padding: 10px 12px;
      max-width: 400px;
      width: 100%;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 10px;
      &:focus {
        color: #ffff;
        border: 1px solid #fff !important;
      }
    }
    &-input-text {
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 10px;
      height: 20px;
      font-size: 14px;
      text-align: center;
      &.error {
        color: red;
      }
    }
    &-btn {
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 3px;
      text-transform: uppercase;
      color: #0c086c;
      background: #69c5f6;
      border-radius: 6px;
      padding: 10px 40px;
      text-align: center;
      cursor: pointer;
      transition: all 0.1s;
      &:hover {
        color: #69c5f6;
        background: #0c086c;
      }
    }
  }

  .page-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    height: 100%;
  }

  .map-style-toggler {
    position: fixed;
    bottom: 10px;
    right: 50px;
    z-index: 1000;
    background: linear-gradient(90deg, #0c086c 0%, #200cbf 100%);
    opacity: 0.8;
    box-shadow: 4px 4px 4px rgb(0 0 0 / 25%);
    -webkit-backdrop-filter: blur(24px);
    backdrop-filter: blur(24px);
    border-radius: 8px;
    opacity: 0.8;
    padding: 10px;
    font-weight: 600;
    letter-spacing: 1px;
    transition: all 0.3s;
    cursor: pointer;
    &:hover {
      opacity: 0.9;
      box-shadow: none;
    }
  }

  .map-fullscreen {
    display: block;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    z-index: 1;
    position: relative;
    min-height: 100vh;
    -webkit-filter: brightness(70%) grayscale(20%);
    filter: brightness(70%) grayscale(20%);
  }

  .wallet-selection {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    //background-color: salmon;
    background: linear-gradient(90deg, rgba(12, 8, 108, 0.9) 0%, rgba(32, 12, 191, 0.9) 100%);
    //backdrop-filter: blur(1px);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    transition: all 0.35s;

    &-inner {
      width: 100%;
      max-width: 820px;
      text-align: center;
    }

    &-title {
      font-weight: 400;
      font-size: 42px;
      line-height: 56px;
      letter-spacing: 10px;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 60px;
    }

    &-wallets {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-bottom: 40px;
    }

    &-wallet {
      border: 1px solid #69c5f6;
      //mix-blend-mode: normal;
      border-radius: 8px;
      padding: 30px 25px;
      margin: 0 12px;
      display: flex;
      align-items: center;
      flex-direction: column;
      min-width: 320px;
      transition: all 0.35s;

      &:hover {
        background-color: rgba(32, 12, 191, 0.3);
      }
    }

    &-info {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
    }

    &-name {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 3px;
      text-transform: uppercase;
      margin-bottom: 58px;
    }

    &-logo {
      &.walletconnect-logo {
        margin-bottom: 17px;
      }
    }

    &-button {
      display: inline-flex;
      background-color: #e15cf9;
      border-color: #e15cf9;
      border-width: 1px;
      border-style: solid;
      border-radius: 8px;
      color: #fff;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 3px;
      text-transform: uppercase;
      padding: 10px 35px;
      width: 100%;
      max-width: 280px;
      text-align: center;
      text-decoration: none;
      justify-content: center;
      cursor: pointer;
      transition: all 0.35s;

      &:hover {
        background-color: #fff;
        color: #e15cf9;
        border-color: #e15cf9;
      }
    }
  }

  //search section

  // .search-section {
  //   position: absolute;
  //   top: 50px;
  //   right: 50px;
  //   z-index: 10;
  // }

  .zoom-btn {
    width: 40px;
    height: 40px;
    z-index: 100;
    background: linear-gradient(90deg, #0c086c 0%, #200cbf 100%);
    opacity: 0.9;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    font-weight: 700;
    position: absolute;
    right: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background: #0c086c;
    }

    &.zoom-in {
      bottom: 50px;
    }

    &.zoom-out {
      bottom: 5px;
    }
  }

  .search-out {
    background: linear-gradient(90deg, #0c086c 0%, #200cbf 100%);
    opacity: 0.9;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    -webkit-backdrop-filter: blur(24px);
    backdrop-filter: blur(24px);
    border-radius: 8px;
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    max-width: 1180px;
    width: 100%;
  }
  .search-notification {
    //display: none;
    .search-notification {
      color: #69c5f6;
      &-header {
        margin-top: 40px;
        margin-bottom: 30px;
        display: flex;
        & > div {
          width: 50%;
        }
        .right-col {
          display: flex;
          justify-content: flex-end;
        }
      }
      &-title {
        font-weight: 500;
        font-size: 32px;
        color: #69c5f6;
        margin-bottom: 5px;
      }

      &-turn-off,
      &-mark-as-read {
        cursor: pointer;
        display: flex;
        align-items: center;
        span {
          font-size: 12px;
          color: #69c5f6;
          transition: all 0.3s;
          border-bottom: 1px solid #69c5f6;
        }
        svg {
          transition: all 0.3s;
          stroke: #69c5f6;
          margin-right: 5px;
        }
        &:hover {
          span {
            transition: all 0.3s;
            border-bottom: 1px solid transparent;
            color: #fff;
          }
          svg {
            stroke: #fff;
          }
        }
      }

      &-mark-as-read {
        // margin-top: 20px;
        text-align: right;
      }
      &-body {
        .search-notification-line {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;

          .search-notification-message-section {
            display: flex;
          }
          .search-notification-message {
            &-point {
              width: 12px;
              height: 12px;
              margin-right: 10px;
              border-radius: 50%;
              background: #69c5f6;
              &.point-active {
                background: #e15cf9;
              }
            }
            &-text {
              font-weight: 700;
              letter-spacing: 3px;
              text-transform: uppercase;
              color: #69c5f6;
            }
            &-link {
              font-weight: 700;
              letter-spacing: 3px;
              text-transform: uppercase;
              color: #69c5f6;
              font-size: 12px;
              display: inline-block;
              margin-left: 10px;
              transition: all 0.3s;
              &:hover {
                color: #fff;
              }
            }
            &-data {
              color: #69c5f6;
              letter-spacing: 2px;
              font-size: 12px;
              span {
                text-decoration: underline;
              }
            }
          }
          .search-notification-icon {
            &-background {
              width: 46px;
              height: 46px;
              border-radius: 50%;
              background: #69c5f6;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
      &-footer {
        .search-notification-toggler {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            transition: all 0.3s;
            fill: #69c5f6;
          }
          &:hover {
            svg {
              fill: #fff;
            }
          }
        }
      }
      &-empty {
        display: block;
        font-size: 12px;
        color: #69c5f6;
        text-align: center;
        letter-spacing: 2px;
        margin: -20px 0 40px 0;
      }
      &-loader {
        height: 80px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        margin-top: -20px;
      }
    }
  }

  .search-top {
    display: flex;
  }

  .search-input-search-section {
    position: relative;
    display: flex;
  }

  .search-suggest {
    position: absolute;
    border: 1px solid #69c5f6;
    background: linear-gradient(90deg, #0c086c 0%, #200cbf 100%);
    opacity: 0.95;
    box-shadow: 4px 4px 4px rgb(0 0 0 / 25%);
    backdrop-filter: blur(2px);
    border-radius: 8px;
    padding: 15px;
    width: 100%;
    max-width: 607px;
    z-index: 100;
    top: 43px;
  }

  .search-suggest-item {
    display: block;
    color: #69c5f6;
    margin-bottom: 10px;
    text-decoration: none;
    transition: all 0.3s;
    cursor: pointer;

    &.hovered,
    &:hover {
      color: #fff;
    }
  }

  .search-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;

    svg {
      fill: #69c5f6;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        fill: #fff;
      }
    }
  }

  .details-section {
    -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    border-radius: 8px;
    border: 1px solid #69c5f6;
    padding: 20px 22px;
  }

  .wallet-btn-out {
    padding: 1px;
    background: linear-gradient(90deg, rgba(107, 198, 246, 1) 0%, rgba(255, 255, 255, 1) 75%);
    border-radius: 8px;

    &:hover {
      background: #fff;
    }

    .wallet-btn {
      background-color: #69c5f6;
      border-radius: 7px;
      font-weight: 600;
      font-size: 18px;
      letter-spacing: 3px;
      text-transform: uppercase;
      color: #0c086c;
      padding: 11px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s ease;
      cursor: pointer;
      white-space: nowrap;

      svg {
        margin-right: 10px;
        fill: #0c086c;
        transition: all 0.3s ease;
      }

      &:hover {
        background-color: #0c086c;
        color: #69c5f6;

        svg {
          margin-right: 10px;
          fill: #69c5f6;
        }
      }
    }
  }

  .search-input-out {
    svg {
      fill: #0c086c;
    }

    &.active {
      svg {
        fill: #69c5f6;
      }
    }
  }

  .search-point-out,
  .search-input-out,
  .search-point-out {
    border: 1px solid #69c5f6;
    -webkit-filter: drop-shadow(1px 2px 10px rgba(0, 0, 0, 0.1));
    filter: drop-shadow(1px 2px 10px rgba(0, 0, 0, 0.1));
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    border-radius: 8px;
    align-items: center;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.purple-border {
      border: 1px solid #e15cf9;
      svg {
        fill: #e15cf9;
      }
    }
  }

  .search-input-section {
    visibility: hidden;
    // display: none;
    // width: 0;
    transition: all 0.3s;

    &.active {
      visibility: visible;
      // width: 100%;
      padding: 10px 0 10px 10px;
      display: flex;
      position: relative;
    }
  }

  .search-input-btn,
  .search-point-out,
  .search-cart-in {
    cursor: pointer;
    padding: 5px 15px;
  }
  .search-point-out,
  .search-input-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9.5px 15px;
  }

  .search-cart-in {
    background: #e15cf9;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .search-input-placeholder {
    position: absolute;
    letter-spacing: 2px;
    color: #69c5f6;
    transition: all 1s ease;
    opacity: 1;

    //text-indent: 0px;
    &.hidden {
      transition: all 0.1s ease;
      // display: none;
      //text-indent: 999px;
      opacity: 0;
    }

    span {
      font-style: italic;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 2px;
      color: #69c5f6;
    }

    svg {
      margin: 0 10px;
    }
  }

  .search-input-field {
    width: 0;
    background: none;
    position: relative;
    z-index: 10;
    border: none !important;
    color: #69c5f6;
    transition: all 0.5s ease;
    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
      cursor: pointer;
      height: 16px;
      width: 16px;
      background: url(../images/times-circle.svg) no-repeat center center;
    }
    &.active {
      width: 545px;
    }
  }

  .search-cart-out {
    display: flex;
    margin-right: 15px;
    align-items: stretch;
    padding: 1px;
    background-color: #fff;
    border-radius: 8px;
    background: linear-gradient(90deg, rgba(225, 92, 249, 1) 0%, rgba(255, 255, 255, 1) 75%);
  }

  .search-input-out:not(.active),
  .search-point-out,
  .search-cart-out {
    transition: all 0.3s ease;

    svg {
      transition: all 0.3s ease;
      fill: #fff;
    }

    &:hover {
      background: #fff;

      .search-cart-in,
      .search-point-in {
        background: #69c5f6;
      }

      svg {
        fill: #0c086c;
      }
    }
  }

  .search-input-out:not(.active),
  .search-point-out {
    svg {
      transition: all 0.3s ease;
      fill: #69c5f6;
    }

    &:hover {
      background: #69c5f6;
      border: 1px solid #fff;

      svg {
        fill: #0c086c;
      }
    }
  }

  .Toastify__toast--info {
    border-radius: 7px;
    background: linear-gradient(90deg, #0c086c 0%, #200cbf 100%);
    opacity: 0.8;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    border: 1px solid rgb(105, 197, 246);
  }

  .Toastify__toast-body {
    letter-spacing: 2px;
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
    // font-style: italic;
  }

  .Toastify__close-button--light {
    color: #69c5f6;
  }

  // .Toastify__toast--default {
  // }
  // .Toastify__toast--info {
  // }
  // .Toastify__toast--warning {
  // }
  .Toastify__toast--success {
    background: #200cbf;
    color: #fff;
    border: 1px solid #69c5f6;
  }

  .Toastify__toast--error {
    background: #200cbf;
    color: red;
    border: 1px solid #69c5f6;
  }

  .Toastify__progress-bar--error,
  .Toastify__progress-bar--success {
    background: #e15cf9;
  }
  .counter {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    transition: opacity 0.1s ease, height 0.3s ease, margin 0.3s ease;
    @media screen and (max-width: 560px) {
      margin-top: 3vh;
    }
  }
  .counter-show {
    height: 74px;
    margin-top: 5vh;
  }
  .counter-hide {
    opacity: 0;
    height: 0px;
    margin-top: 0vh;
  }
  .counterItem-dots {
    display: flex;
    width: 8px;
    flex-direction: column;
    justify-content: center;
    color: rgba(255, 255, 255, 0.45);
    text-align: center;
    font-family: Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }

  .counterItem {
    display: flex;
    flex-direction: column;
    align-items: center;

    .counterItem-container {
      width: 64px;
      height: 74px;
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 8px;

      border: 1px solid #7490c5;
      background: linear-gradient(93deg, #4d71b4 28.64%, #4b70b4 59.2%, #476eb3 77.54%);
      span {
        font-weight: 700;
        font-size: 28px;
        font-family: Poppins;
        line-height: 33px;
      }
    }

    .counterItem-title {
      font-family: Poppins;
      font-weight: 400;
      size: 10px;
      line-height: 13px;
      margin-top: 8px;
    }
  }

  .transfer {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin-top: 7vh;
    .transfer-text {
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      @media screen and (max-width: 560px) {
        // height: auto;
        font-size: 14px;
      }
    }
    @media screen and (max-width: 560px) {
      margin-top: 3vh;
    }
  }
  /* Loader 1 */
  .loader-1 {
    height: 20px;
    position: relative;
    width: 20px;
    -webkit-animation: loader-1-1 4.8s linear infinite;
    animation: loader-1-1 4.8s linear infinite;
  }

  @-webkit-keyframes loader-1-1 {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes loader-1-1 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loader1-center {
    display: block;

    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .loader-1 span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 20px;
    width: 20px;
    clip: rect(0, 20px, 20px, 10px);
    -webkit-animation: loader-1-2 1.2s linear infinite;
    animation: loader-1-2 1.2s linear infinite;
  }
  @-webkit-keyframes loader-1-2 {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(220deg);
    }
  }
  @keyframes loader-1-2 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(220deg);
    }
  }
  .loader-1 span::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 20px;
    width: 20px;
    clip: rect(0, 20px, 20px, 10px);
    border: 3px solid #fff;
    border-radius: 50%;
    -webkit-animation: loader-1-3 1.2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
    animation: loader-1-3 1.2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  }
  @-webkit-keyframes loader-1-3 {
    0% {
      -webkit-transform: rotate(-140deg);
    }
    50% {
      -webkit-transform: rotate(-160deg);
    }
    100% {
      -webkit-transform: rotate(140deg);
    }
  }
  @keyframes loader-1-3 {
    0% {
      transform: rotate(-140deg);
    }
    50% {
      transform: rotate(-160deg);
    }
    100% {
      transform: rotate(140deg);
    }
  }
}
