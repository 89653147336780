.search-details {
  margin-top: 20px;
}

.search-details-top {
  display: flex;
  gap: 10px;
}

.search-details-info {
  display: block;

  &.hidden {
    display: none;
  }
}

.details-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 3px;
  margin-bottom: 5px;
}

.details-parcels {
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 2px;
  color: #e15cf9;
  margin-bottom: 20px;
}

.details-name {
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 2px;
}

.details-icon {
  margin-right: 5px;
}

.details-related {
  display: flex;
}

.details-related-text {
  font-size: 12px;
  letter-spacing: 2px;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  color: #69c5f6;
}

.details-coordinates {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 2px;
  color: #69c5f6;
}

.details-address {
  display: flex;
}

.details-price-row {
  display: flex;
  align-items: flex-end;
  margin-bottom: 5px;
}

.details-price {
  font-weight: 700;
  font-size: 56px;
  line-height: 46px;
  letter-spacing: 8px;
  text-transform: uppercase;
  color: #e15cf9;
  margin-left: 5px;
}

.details-currency {
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #e15cf9;
}

.details-price-parcel {
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 2px;
  color: #69c5f6;
  margin-left: 5px;
}

.details-price-line,
.details-owner-line {
  display: flex;
}

.details-price-section {
  margin-left: 5px;
}

.details-price-line {
  align-items: flex-start;
}

.details-owner-line {
  display: flex;
  align-items: center;
}

.details-owner-line {
  margin-top: 20px;

  svg {
    margin-left: 5px;
  }
}

.details-owner {
  margin-left: 20px;
}

.details-buy-btn-out {
  padding: 1px;
  background: #e15cf9;
  box-shadow: 1px 2px 10px rgb(0 0 0 / 10%);
  background: linear-gradient(90deg, rgb(225, 92, 249) 0%, rgb(255, 255, 255) 75%);
  border-radius: 8px;
  margin-top: 10px;
}

.details-column {
  &:nth-child(1) {
    width: 40%;
    height: 100%;
  }

  &:nth-child(2) {
    width: 30%;

    .details-section {
      height: 100%;
    }
  }

  &:nth-child(3) {
    width: 30%;
  }
}

.details-buy-btn-inner {
  display: flex;
  text-align: center;
  justify-content: center;
  padding: 20px;
  background: #e15cf9;
  border-radius: 7px;
}

.details-buy-btn-flex {
  display: flex;
}

.details-buy-btn-text {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 600;
  margin-left: 5px;
  cursor: pointer;
}

.details-currency-section {
  display: flex;
  margin-top: 20px;
}

.details-currency-table {
  width: 70%;
  padding: 30px 25px;
  border-radius: 8px;
  border: 1px solid #69c5f6;
}

.details-currency-amount {
  margin-top: 20px;
  font-weight: 600;
  font-size: 32px;
  letter-spacing: 4px;
  color: #e15cf9;
  padding-left: 10px;
}

.details-currency-table-line {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}

.details-currency-table-left,
.details-currency-table-right {
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #ffffff;
}

.details-clear-section {
  display: flex;
}

.details-clear {
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 2px;
  text-decoration-line: underline;
  color: #69c5f6;
  width: 40%;
  text-align: right;
  padding-right: 10px;
}

.details-buy-section {
  display: flex;
  margin-top: 15px;

  .out-buy-eth {
    background: #69c5f6;
    box-shadow: 1px 2px 10px rgb(0 0 0 / 10%);
    background: linear-gradient(90deg, rgba(105, 197, 246, 1) 0%, rgb(255, 255, 255) 75%);
  }

  .details-buy-btn-inner {
    padding: 15px;

    &.buy-eth {
      background: #69c5f6;
    }
  }

  .details-buy-btn {
    width: 50%;
    margin-right: 10px;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }
  }
}
