:root {
  --onboard-font-family-normal: Poppins, sans-serif;
  --onboard-font-family-semibold: Poppins, sans-serif;
  --onboard-font-family-light: Poppins, sans-serif;
  --onboard-connect-sidebar-background: #0c086c;
  --onboard-connect-header-background: linear-gradient(90deg, #0c086c 0%, #200cbf 100%);
  --onboard-main-scroll-container-background: linear-gradient(90deg, #0c086c 0%, #200cbf 100%);
  --onboard-gray-700: #fff;
  --onboard-connect-header-color: #fff;
  --onboard-wallet-button-background: #69c5f6;
  --onboard-wallet-button-color: #0c086c;
  --onboard-close-button-color: #0c086c;
  --onboard-close-button-background: #69c5f6;
  --onboard-wallet-app-icon-border-color: transparent;
  --onboard-primary-100: #69c5f6;
  --onboard-gray-600: #0c086c;
  --onboard-gray-500: #0c086c;
  --onboard-white: #69c5f6;
  --onboard-warning-100: #69c5f6;
  --onboard-success-100: #69c5f6;
  --onboard-success-700: #0c086c;
  --onboard-link-color: #69c5f6;
  --onboard-primary-500: #0c086c;
}

body .walletconnect-modal__mobile__toggle a {
  color: #0c086c;
}
body .walletconnect-qrcode__text,
body .walletconnect-modal__footer a,
body .-cbwsdk-css-reset .-cbwsdk-extension-dialog-box-bottom.light,
body .-cbwsdk-css-reset .-cbwsdk-extension-dialog-box-top.light {
  color: #0c086c;
  font-family: Poppins, sans-serif;
}
body .walletconnect-modal__close__wrapper,
body .-cbwsdk-css-reset .-cbwsdk-extension-dialog-box-cancel,
body .-cbwsdk-css-reset .-cbwsdk-extension-dialog-box-bottom.light,
body .-cbwsdk-css-reset .-cbwsdk-extension-dialog-box-top.light {
  background-color: #69c5f6;
}
body .walletconnect-modal__close__line1,
body .walletconnect-modal__close__line2,
body .walletconnect-search__input {
  border: 1px solid #0c086c;
}
body .walletconnect-search__input {
  background-color: #fff;
}
body .walletconnect-modal__mobile__toggle_selector {
  background-color: #69c5f6;
}
